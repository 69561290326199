import React, { ComponentProps, ChangeEvent, FormEvent, useState, useEffect } from 'react'
import { CookieKeys, getCookie, removeCookie, setCookie } from 'services/Cookies'
import { useRouter } from 'hooks/useRouter'
import { useToast } from 'contexts/ToastContext'
import { useTranslation } from 'hooks/useTranslation'
import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { TextInput } from 'components/TextInput'
import { LoginFormRoot, CreateAMealPlan } from './LoginForm.styles'
import { Radio } from '@material-ui/core'
import { useLogin } from 'api/mutations/useLogin'
import { useSmsCode } from 'api/mutations/useSmsCode'

type LoginFormOwnProps = ComponentProps<typeof LoginFormRoot>
export type LoginFormProps = LoginFormOwnProps & {
  forward?: string
  isExistingEmail?: boolean
}

export type errorObject = {
  email: string | undefined
  phoneNumber: string | undefined
}

export type ClientData = {
  clientTokenId: string
  token: string
  createdAt: Date
}

export const LoginFormV2 = ({ forward, isExistingEmail }: LoginFormProps) => {
  const { t } = useTranslation()
  const { addToast, removeToast } = useToast()
  const { login } = useLogin()
  const { smsCode } = useSmsCode()
  const router = useRouter({ clearQuery: true })

  const [selectPasswordOption, setSelectPasswordOption] = useState(1)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')

  const [formErrors, setFormErrors] = useState<errorObject>({
    phoneNumber: undefined,
    email: undefined,
  })

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectPasswordOption(Number(event.target.value))
  }

  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numbersOnly = e.target.value.replace(/\D/g, '')
    e.target.value = numbersOnly

    if (!e.target.value && selectPasswordOption === 2) {
      setFormErrors({
        ...formErrors,
        phoneNumber: 'Please enter a phone number',
      })
      setPhone(numbersOnly)
      return
    }
    if (e.target.value.length < 10) {
      setFormErrors({
        ...formErrors,
        phoneNumber: 'Please enter 10 digits',
      })
      setPhone(numbersOnly)
      return
    }
    setFormErrors({ ...formErrors, phoneNumber: undefined })
    setPhone(numbersOnly)
  }

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'email') setEmail(e.target.value.toLowerCase())
    if (e.target.name === 'password') setPassword(e.target.value)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (selectPasswordOption === 1) {
      login(
        { email, password, guestId: getCookie(CookieKeys.GUEST_ID) },
        {
          onSuccess: ({ accessToken }) => {
            setCookie(CookieKeys.SESSION, accessToken)
            removeCookie(CookieKeys.GUEST_ID)
            router.replace(forward || '/user')
          },
          onError: () => {
            addToast({
              title: t('login:toast.error.title'),
              message: t('login:toast.error.message'),
              duration: 5000,
              tone: 'error',
              onClose: () => removeToast('login-error'),
              id: 'login-error',
            })
          },
        }
      )
    }
    if (selectPasswordOption === 2) {
      smsCode(
        { phoneNumber: phone },
        {
          onSuccess: () => {
            router.replace(forward || `/login/confirmation/`)
          },
          onError: () => {
            addToast({
              title: 'Oops! Something went wrong!',
              message: 'Failed to send your login link. Please try again.',
              duration: 5000,
              tone: 'error',
              onClose: () => removeToast('sms-code-error'),
              id: 'sms-code-error',
            })
          },
        }
      )
    }
  }

  return (
    <LoginFormRoot onSubmit={handleSubmit} justifyContent="start">
      <Stack direction="column" gap="4" css={{ width: '100%' }}>
        <Text size={{ '@initial': 'h4', '@bp3': 'h3' }} as="h3" weight="bold" color="accent-black">
          {isExistingEmail ? t('login:existing-login') : t('login:login')}
        </Text>

        <Stack direction="column" gap="2" alignItems="flex-start">
          <Text align="center" color="accent-black">
            {t('login:select-login')}
          </Text>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="2">
            <Stack direction="row" alignItems="center" gap="1">
              <Radio
                checked={selectPasswordOption === 1}
                onChange={onRadioChange}
                value="1"
                name="password"
                inputProps={{ 'aria-label': 'Use Password' }}
              />
              <Text>{t('login:password')}</Text>
            </Stack>
            <Stack direction="row" alignItems="center" gap="1">
              <Radio
                checked={selectPasswordOption === 2}
                onChange={onRadioChange}
                value="2"
                name="text"
                inputProps={{ 'aria-label': 'Text Password' }}
              />
              <Text>{t('login:text')}</Text>
            </Stack>
          </Stack>
        </Stack>

        {selectPasswordOption === 1 && (
          <Stack direction="column" gap="4">
            <Stack direction="column" gap={{ '@initial': '4', '@bp3': '3' }}>
              <Text size={{ '@initial': 'body', '@bp3': 'headline' }} color="accent-black">
                {isExistingEmail
                  ? t('login:existing-login-description')
                  : t('login:login-description')}
              </Text>
              <Stack direction="column" gap="2">
                <TextInput
                  type="email"
                  label={t('login:email')}
                  name="email"
                  onChange={onInputChange}
                  removeDiacritics={true}
                />
                <TextInput
                  type="password"
                  label={t('login:password')}
                  name="password"
                  onChange={onInputChange}
                  removeDiacritics={true}
                />
              </Stack>
            </Stack>
            <Link href="/auth/reset-password" passHref>
              <Text size="small" as="a" color="accent-black" align="right">
                {t('login:forgot')}
              </Text>
            </Link>
          </Stack>
        )}

        {selectPasswordOption === 2 && (
          <Stack direction="column" gap="4">
            <Stack direction="column" gap={{ '@initial': '4', '@bp3': '3' }}>
              <Text size={{ '@initial': 'body', '@bp3': 'headline' }} color="accent-black">
                {t('login:provide-phone')}
              </Text>
              <Stack direction="column" gap={{ '@initial': '5', '@bp3': '4' }}>
                <TextInput
                  type="phone"
                  label={t('login:phone')}
                  name="phone"
                  onChange={onPhoneChange}
                  removeDiacritics={true}
                  error={!!formErrors.phoneNumber}
                  helperText={formErrors.phoneNumber}
                  maxLength={10}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack direction="column" gap="4" css={{ width: '100%' }}>
        <Button>{selectPasswordOption === 1 ? t('login:login') : t('login:send-text')}</Button>
        <CreateAMealPlan
          direction={{ '@initial': 'column', '@bp3': 'row' }}
          gap={{ '@initial': '1', '@bp3': '0' }}
        >
          <Text>
            {t('login:no-account')}
            {' '}
          </Text>
          <Text align="center">
            <Text>
              {t('login:create')}
              {' '}
            </Text>
            <Link href="/meal-plan" passHref>
              <Text color="accent-black" as="a">
                {t('login:meal-plan')}
              </Text>
            </Link>
          </Text>
        </CreateAMealPlan>
      </Stack>
    </LoginFormRoot>
  )
}

LoginFormV2.displayName = 'LoginFormV2'
