import { useMutation, UseMutationOptions } from 'react-query'
import { ApiClient } from 'services/ApiClient'
import { Api } from 'types/Api'

export namespace SmsCode {
  export type Response = { clientTokenId: string; token: string; createdAt: Date }
  export type Variables = { phoneNumber: string }
  export type Error = Api.Error
  export type Options = UseMutationOptions<Response, Error, Variables>
}

export const useSmsCode = (options?: SmsCode.Options) => {
  const mutationFn = async (data: SmsCode.Variables) => {
    return ApiClient.post<SmsCode.Response>('/v1/clients/sms-login', { data })
  }

  const mutation = useMutation<SmsCode.Response, SmsCode.Error, SmsCode.Variables>(
    mutationFn,
    options
  )

  return { ...mutation, smsCode: mutation.mutate }
}
