import { styled } from 'stitches.config'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'

export const LoginFormRoot = styled('form', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '$6',
  backgroundColor: '$tertiary-200',
  padding: '$14 $3',
  minHeight: '100vh',

  '@bp1': {
    padding: '$10 $3',
  },

  '@bp2': {
    $$headerHeight: '80px',
    padding: '$10 144px',
    paddingTop: 'calc($$headerHeight + $10)',
  },

  '@bp3': {
    maxWidth: 'calc($max/2)',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '$7',
  },
  variants: {
    justifyContent: {
      start: {
        justifyContent: 'flex-start',
        '@bp2': {
          justifyContent: 'center',
        },
      },
      spaceBetween: {
        justifyContent: 'space-between',
      },
    },
  },
})

export const CreateAMealPlan = styled(Stack, {
  alignSelf: 'center',
  '&': {
    [`${Text}`]: {
      whiteSpace: 'nowrap',
    },
  },
})
