import React, { useState, FormEvent, ChangeEvent } from 'react'
import { useLogin } from 'api/mutations/useLogin'
import { Header } from 'components/Header'
import { Layout } from 'components/Layout'
import { useRouter } from 'hooks/useRouter'
import { LoginForm } from './components/LoginForm'
import { LoginLayout } from './components/LoginLayout'
import { CookieKeys, getCookie, removeCookie, setCookie } from 'services/Cookies'
import { useToast } from 'contexts/ToastContext'
import { useTranslation } from 'hooks/useTranslation'

type LoginProps = {
  isExistingEmail?: boolean
  forward?: string
}

export const Login = ({ isExistingEmail = false, forward }: LoginProps) => {
  const header = <Header />

  const { t } = useTranslation()
  const { addToast, removeToast } = useToast()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { login } = useLogin()
  const router = useRouter({ clearQuery: true })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    login(
      { email, password, guestId: getCookie(CookieKeys.GUEST_ID) },
      {
        onSuccess: ({ accessToken }) => {
          setCookie(CookieKeys.SESSION, accessToken)
          removeCookie(CookieKeys.GUEST_ID)
          router.replace(forward || '/user')
        },
        onError: () => {
          addToast({
            title: t('login:toast.error.title'),
            message: t('login:toast.error.message'),
            duration: 5000,
            tone: 'error',
            onClose: () => removeToast('login-error'),
            id: 'login-error',
          })
        },
      }
    )
  }

  return (
    <Layout header={header} hideCTA>
      <LoginLayout>
        <LoginForm
          isExistingEmail={isExistingEmail}
          onSubmit={handleSubmit}
          onEmailChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value.toLowerCase())
          }
          onPasswordChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />
      </LoginLayout>
    </Layout>
  )
}
