import { useMutation, UseMutationOptions } from 'react-query'
import { ApiClient } from 'services/ApiClient'
import { Api } from 'types/Api'

export namespace Login {
  export type Response = { accessToken: string }
  export type Variables = { email: string; password: string; guestId?: string }
  export type Error = Api.Error
  export type Options = UseMutationOptions<Response, Error, Variables>
}

export const useLogin = (options?: Login.Options) => {
  const mutationFn = async (data: Login.Variables) => {
    return ApiClient.post<Login.Response>('/v1/clients/login', { data })
  }

  const mutation = useMutation<Login.Response, Login.Error, Login.Variables>(mutationFn, options)

  return { ...mutation, login: mutation.mutate }
}
