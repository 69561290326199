import { GetServerSideProps } from 'next'
import { useTranslation } from 'hooks/useTranslation'
import Head from 'next/head'
import React from 'react'
import { Login } from 'features/Login'
import { LoginV2 } from 'features/Login/LoginV2'
import { withGuestAccess } from 'utils/withAuthorization'
import { ENV } from '../../constants/EnvHelp'

export const getServerSideProps: GetServerSideProps = withGuestAccess

export default function LoginPage(props: any) {
  const { t } = useTranslation()

  const getFlags = ENV.getVersionFlags()

  const smsLogin = getFlags?.smsLogin === '07_24_sms_login_v1' ? true : false

  return (
    <div>
      <Head>
        <title>{t('login:login')}</title>
        <meta name="title" content={t('login:meta-title')} />
        <meta name="description" content={t('login:meta-description')} />
      </Head>

      <main>
        {smsLogin ? <LoginV2 forward={props.forward} /> : <Login forward={props.forward} />}
      </main>
    </div>
  )
}
