import React from 'react'
import { Header } from 'components/Header'
import { Layout } from 'components/Layout'
import { LoginFormV2 } from './components/LoginForm/LoginFormV2'
import { LoginLayout } from './components/LoginLayout'

type LoginProps = {
  isExistingEmail?: boolean
  forward?: string
}

export const LoginV2 = ({ isExistingEmail = false, forward }: LoginProps) => {
  const header = <Header />

  return (
    <Layout header={header} hideCTA>
      <LoginLayout>
        <LoginFormV2 isExistingEmail={isExistingEmail} forward={forward} />
      </LoginLayout>
    </Layout>
  )
}
