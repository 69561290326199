import React, { ComponentProps, ChangeEvent, FormEvent, useState, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button } from 'components/Button'
import { Link } from 'components/Link'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { TextInput } from 'components/TextInput'
import { LoginFormRoot, CreateAMealPlan } from './LoginForm.styles'

type LoginFormOwnProps = ComponentProps<typeof LoginFormRoot>
export type LoginFormProps = LoginFormOwnProps & {
  isExistingEmail?: boolean
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
  onEmailChange: (e: ChangeEvent<HTMLInputElement>) => void
  onPasswordChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const LoginForm = ({
  isExistingEmail = false,
  onSubmit,
  onEmailChange,
  onPasswordChange,
}: LoginFormProps) => {
  const { t } = useTranslation()

  return (
    <LoginFormRoot onSubmit={onSubmit}>
      <Stack direction="column" gap={{ '@initial': '4', '@bp3': '7' }} css={{ width: '100%' }}>
        <Text size={{ '@initial': 'h4', '@bp3': 'h3' }} as="h3" weight="bold" color="accent-black">
          {isExistingEmail ? t('login:existing-login') : t('login:login')}
        </Text>
        <Stack direction="column" gap="4">
          <Stack direction="column" gap={{ '@initial': '4', '@bp3': '3' }}>
            <Text size={{ '@initial': 'body', '@bp3': 'headline' }} color="accent-black">
              {isExistingEmail
                ? t('login:existing-login-description')
                : t('login:login-description')}
            </Text>
            <Stack direction="column" gap={{ '@initial': '5', '@bp3': '4' }}>
              <TextInput
                type="email"
                label={t('login:email')}
                name="email"
                onChange={onEmailChange}
                removeDiacritics={true}
              />
              <TextInput
                type="password"
                label={t('login:password')}
                name="password"
                onChange={onPasswordChange}
                removeDiacritics={true}
              />
            </Stack>
          </Stack>
          <Link href="/auth/reset-password" passHref>
            <Text size="small" as="a" color="accent-black" align="right">
              {t('login:forgot')}
            </Text>
          </Link>
        </Stack>
      </Stack>
      <Stack direction="column" gap="4" css={{ width: '100%' }}>
        <Button>{t('login:login')}</Button>
        <CreateAMealPlan
          direction={{ '@initial': 'column', '@bp3': 'row' }}
          gap={{ '@initial': '1', '@bp3': '0' }}
        >
          <Text>
            {t('login:no-account')}
            {' '}
          </Text>
          <Text align="center">
            <Text>
              {t('login:create')}
              {' '}
            </Text>
            <Link href="/meal-plan" passHref>
              <Text color="accent-black" as="a">
                {t('login:meal-plan')}
              </Text>
            </Link>
          </Text>
        </CreateAMealPlan>
      </Stack>
    </LoginFormRoot>
  )
}

LoginForm.displayName = 'LoginForm'
